import styled from 'styled-components';
import { Heading, TextField, Label, Button, Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  PageWrapper: styled.div`
    background: ${Theme.colours.white};
    min-height: 100vh;
  `,

  CenterArea: styled.div`
    background: ${Theme.colours.grey_f5};
  `,

  ContentWrapper: styled.div`
    padding: 53px 0;
  `,

  ContentWrapperCentered: styled.div`
    margin: 0 auto;
    padding: 140px 0 80px;
    min-height: 55vh;
    box-sizing: border-box;

    @media (max-width: 768px) {
      text-align: center;
      padding: 100px 0 80px;
    }
  `,

  Heading: styled(Heading)`
    font-size: 2.8rem;
    font-weight: 100;
    letter-spacing: 0.01375rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 38px;
      line-height: 46px;
    }
  `,

  TextFieldContainer: styled.div`
    max-width: ${remCalc(380)};
    margin: 20px auto;
  `,

  Label: styled(Label)`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
    text-align: left;
  `,

  TextField: styled(TextField)`
    box-sizing: border-box;
    padding: 10px 13px;
  `,

  ButtonsContainer: styled.div`
    text-align: right;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      text-align: center;
    }
  `,

  Button: styled(Button)`
    height: auto;
    margin: 0 10px;
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
    min-width: 0;
  `,

  FooterContainer: styled.div`
    background: ${Theme.colours.white};
    width: 100%;
    padding: 1px 0;

    hr {
      margin-block-start: 0;
    }

    @media (max-width: 768px) {
      padding-top: 60px;
    }
  `,

  ModalHeader: styled(Heading)`
    font-size: 37px;
    margin-bottom: 28px;
  `,

  ModalText: styled.p`
    font-size: 17px;
    line-height: 29px;
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
  `,

  IconList: styled.ul`
    list-style: none;
    margin: 20px 0;
    padding: 0;
  `,

  IconListItem: styled.li`
    padding: 0;
    margin: 20px 0;
    display: flex;
  `,

  Icon: styled.span`
    flex: 0 1 30px;
    color: ${Theme.colours.grey_66};

    &.green {
      color: ${Theme.colours.green_60};
    }
  `,

  IconListItemText: styled.span`
    flex: 1;
    font-size: 14px;
  `
};
