import styled from 'styled-components';
import { Heading } from '@lux/components';
import { Theme } from '@lux/components';
import { Paragraph } from '@lux/components/src/components/baseElements';

export default {
  OrderBox: styled(Paragraph)`
    border-radius: 5px;
    background-color: ${Theme.colours.white};
    border: 1px solid ${Theme.colours.grey_e5};
    margin: 53px 0 43px;
    padding: 25px 20px;
    text-align: left;
  `,

  OrderHeading: styled(Heading)`
    font-family: AvenirLTStd-Heavy, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 8px;
  `,

  OrderContract: styled.p`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
    margin: 0 0 25px;
  `,

  OrderDetails: styled.div`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 25px;

    p {
      margin-bottom: 25px;
    }
  `,

  OrderPrices: styled.div`
    text-align: right;
  `,

  Price: styled.span`
    font-family: AvenirLTStd-Heavy, Arial, Helvetica, sans-serif;
    font-size: 21px;
  `,

  PriceDuration: styled.span`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
  `,

  CurrentPrice: styled.div`
    margin-bottom: 8px;
  `,

  OriginalPrice: styled.div`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
  `,

  DetailHeader: styled.h6`
    font-family: AvenirLTStd-Book, Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
  `,

  DetailText: styled.p`
    font-family: AvenirLTStd-Heavy, Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: left;
  `,

  FooterInfoText: styled.p`
    font-size: 12px;
    margin-top: 24px;
    text-align: left;
  `
};
