import React from 'react';
import GSC from '../styles';
import { Header, Footer, Grid, Row, Column, Heading } from '@lux/components';
import axios from 'axios';

export default class VerifyIdentityPage extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      mobileNumber: '',
      mobileNumberError: '',
      isSubmitting: false,
      isValid: false
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');

    if (!token) this.props.history.push('/error');
  }

  onChangeHandler = key => {
    return event => {
      const isValid =
        key === 'mobileNumber'
          ? event.target.value.match(/^\d{8,}$/)
          : this.state.isValid;

      this.setState({
        [key]: event.target.value,
        isValid
      });
    };
  };

  onBlur = () => {
    if (
      !this.state.mobileNumber ||
      !this.state.mobileNumber.match(/^\d{8,}$/)
    ) {
      this.setState({
        mobileNumberError: 'Please enter a valid mobile number',
        isValid: false
      });
    } else {
      this.setState({
        mobileNumberError: '',
        isValid: true
      });
    }
  };

  onSubmit = () => {
    if (this.state.isSubmitting || !this.state.isValid) {
      return;
    }

    this.setState({
      isSubmitting: true
    });

    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    axios
      .get(`${process.env.LUX_API_URL}/api/v1/orders/confirm`, {
        params: {
          token,
          mobile: this.state.mobileNumber
        }
      })
      .then(res => {
        this.props.history.push('/confirm-order', {
          data: {
            ...res.data,
            token,
            mobile: this.state.mobileNumber
          }
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          this.props.history.push('/error?code=401');
          return;
        }
        this.props.history.push('/error');
      });
  };

  render() {
    return (
      <GSC.PageWrapper>
        <Header logo={{ href: '#', alt: 'Singtel.com' }} />

        <GSC.CenterArea>
          <Grid>
            <Row start="sm" center="md">
              <Column lg={4} md={6} sm={12}>
                <GSC.ContentWrapperCentered>
                  <Heading level={1}>Customer Verification</Heading>
                  <GSC.TextFieldContainer>
                    <GSC.Label>
                      Enter your mobile number submitted in the request.
                    </GSC.Label>
                    <GSC.TextField
                      id="mobileNumber"
                      type="text"
                      placeholder="Mobile Number"
                      value={this.state.mobileNumber}
                      onChange={this.onChangeHandler('mobileNumber')}
                      onBlur={this.onBlur}
                      validation={
                        this.state.mobileNumberError ? 'danger' : null
                      }
                      hintMessage={this.state.mobileNumberError}
                    />
                  </GSC.TextFieldContainer>
                </GSC.ContentWrapperCentered>
              </Column>
            </Row>

            <Row center="sm" end="md">
              <Column sm={12}>
                <GSC.ButtonsContainer>
                  <GSC.Button
                    id="verifyIdentitySubmitButton"
                    disabled={this.state.isSubmitting || !this.state.isValid}
                    onClick={this.onSubmit}
                  >
                    Submit
                  </GSC.Button>
                </GSC.ButtonsContainer>
              </Column>
            </Row>
          </Grid>
        </GSC.CenterArea>

        <GSC.FooterContainer>
          <Footer
            copyrightText="© Singtel (CRN: 199201624D) All Rights Reserved."
            links={[
              { text: 'DATA PROTECTION', link: '#' },
              { text: 'CAREERS', link: '#' },
              { text: 'TERMS OF USE', link: '#' },
              { text: 'COPYRIGHT NOTICES', link: '#' },
              { text: 'CONTACT US', link: '#' },
              { text: 'SINGTEL GLOBAL OFFICES', link: '#' },
              { text: 'STORE LOCATOR', link: '#' }
            ]}
          />
        </GSC.FooterContainer>
      </GSC.PageWrapper>
    );
  }
}
