import React from 'react';
import GSC from '../styles';
import SC from './styles';
import {
  Header,
  Grid,
  Row,
  Column,
  Heading,
  Modal,
  Footer
} from '@lux/components';
import IconTick from '@lux/components/src/assets/svgs/tick-filled.svg';
import axios from 'axios';
import qs from 'qs';

export default class ConfirmOrderPage extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      isThankYouModalOpened: false,
      isConfirmingOrder: false
    };
  }

  componentDidMount() {
    if (!this.checkPreconditions()) {
      this.props.history.push('/error');
    }
  }

  checkPreconditions = () => {
    const history = this.props.history;
    return (
      history.location &&
      history.location.state &&
      history.location.state.data &&
      history.location.state.data.token
    );
  };

  confirmOrder = () => {
    const { token, mobile } = this.props.history.location.state.data;

    this.setState({
      isConfirmingOrder: true
    });
    axios
      .post(
        `${process.env.LUX_API_URL}/api/v1/orders/confirm`,
        qs.stringify({
          token,
          mobile
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .then(() => {
        this.setState({
          isThankYouModalOpened: true
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          this.props.history.push('/error?code=401');
          return;
        }
        this.props.history.push('/error');
      });
  };

  dismissThankYou = () => {
    this.setState({
      isThankYouModalOpened: false
    });
  };

  render() {
    if (!this.checkPreconditions()) return null;
    const data = this.props.history.location.state.data;

    return (
      <GSC.PageWrapper>
        <Header logo={{ href: '#', alt: 'Singtel.com' }} />

        <GSC.CenterArea>
          <Grid>
            <Row center="sm">
              <Column sm={12} md={10}>
                <GSC.ContentWrapper>
                  <Heading level={1}>
                    Please review and confirm your order
                  </Heading>

                  {data.products.map(prod => {
                    return (
                      <div className="product" key={prod.productId}>
                        <Row center="sm">
                          <Column sm={12} md={10}>
                            <SC.OrderBox>
                              <Row>
                                <Column sm={7} md={7} lg={7}>
                                  <SC.OrderHeading>
                                    {prod.planName}
                                  </SC.OrderHeading>
                                  <SC.OrderContract>
                                    {prod.contractDuration}
                                  </SC.OrderContract>
                                </Column>
                                <Column sm={5} md={5} lg={5}>
                                  <SC.OrderPrices>
                                    <SC.CurrentPrice>
                                      <SC.Price>{prod.actualPrice}</SC.Price>
                                      <SC.PriceDuration>
                                        {prod.periodCost}
                                      </SC.PriceDuration>
                                    </SC.CurrentPrice>
                                    <SC.OriginalPrice>
                                      U.P. {prod.usualPrice}
                                      {prod.periodCost}
                                    </SC.OriginalPrice>
                                  </SC.OrderPrices>
                                </Column>
                              </Row>

                              <Row>
                                <Column sm={12}>
                                  <SC.OrderDetails
                                    dangerouslySetInnerHTML={{
                                      __html: prod.planDesc
                                    }}
                                  />
                                </Column>
                              </Row>
                            </SC.OrderBox>
                          </Column>
                        </Row>

                        <Row center="sm">
                          <Column sm={12} md={10}>
                            <Row>
                              <Column sm={12} md={3} lg={3}>
                                <SC.DetailHeader>
                                  Customer Name:
                                </SC.DetailHeader>
                                <SC.DetailText>
                                  {data.customerName}
                                </SC.DetailText>
                              </Column>
                              <Column sm={12} md={3} lg={3}>
                                <SC.DetailHeader>
                                  Mobile Number:
                                </SC.DetailHeader>
                                <SC.DetailText>
                                  {data.customerMobile}
                                </SC.DetailText>
                              </Column>
                              <Column sm={12} md={3} lg={3}>
                                <SC.DetailHeader>
                                  Installation Address:
                                </SC.DetailHeader>
                                <SC.DetailText>
                                  {prod.customerPreferences.address}
                                </SC.DetailText>
                              </Column>
                              <Column sm={12} md={3} lg={3}>
                                <SC.DetailHeader>
                                  Ambassador Name:
                                </SC.DetailHeader>
                                <SC.DetailText>{data.agentName}</SC.DetailText>
                              </Column>
                            </Row>

                            <Row>
                              <Column sm={12}>
                                <SC.FooterInfoText>
                                  Our Singtel sales representative will be
                                  contacting you to follow up on your order.
                                </SC.FooterInfoText>
                              </Column>
                            </Row>
                          </Column>
                        </Row>
                      </div>
                    );
                  })}
                </GSC.ContentWrapper>
              </Column>
            </Row>

            <Row>
              <Column sm={12}>
                <GSC.ButtonsContainer>
                  <GSC.Button
                    id="confirmOrder"
                    onClick={this.confirmOrder}
                    disabled={this.state.isConfirmingOrder}
                  >
                    Confirm
                  </GSC.Button>
                </GSC.ButtonsContainer>
              </Column>
            </Row>
          </Grid>
        </GSC.CenterArea>

        <GSC.FooterContainer>
          <Footer
            copyrightText="© Singtel (CRN: 199201624D) All Rights Reserved."
            links={[
              { text: 'DATA PROTECTION', link: '#' },
              { text: 'CAREERS', link: '#' },
              { text: 'TERMS OF USE', link: '#' },
              { text: 'COPYRIGHT NOTICES', link: '#' },
              { text: 'CONTACT US', link: '#' },
              { text: 'SINGTEL GLOBAL OFFICES', link: '#' },
              { text: 'STORE LOCATOR', link: '#' }
            ]}
          />
        </GSC.FooterContainer>

        <Modal id="thankYouModal" open={this.state.isThankYouModalOpened}>
          <GSC.ModalHeader>Thank You</GSC.ModalHeader>

          <GSC.ModalText>
            Your request has been submitted and we will be in touch with you
            soon!
          </GSC.ModalText>

          <GSC.IconList>
            <GSC.IconListItem>
              <GSC.Icon>
                <IconTick className="green" size={16} fill="#12CB60" />
              </GSC.Icon>
              <GSC.IconListItemText>
                Submit a request via Singtel Ambassador.
              </GSC.IconListItemText>
            </GSC.IconListItem>
            <GSC.IconListItem>
              <GSC.Icon>
                <IconTick className="green" size={16} fill="#12CB60" />
              </GSC.Icon>
              <GSC.IconListItemText>
                Complete your request.
              </GSC.IconListItemText>
            </GSC.IconListItem>
            <GSC.IconListItem>
              <GSC.Icon>
                <IconTick size={16} fill="#A1A1A1" />
              </GSC.Icon>
              <GSC.IconListItemText>
                Singtel representative will get in touch with you on your
                request.
              </GSC.IconListItemText>
            </GSC.IconListItem>
            <GSC.IconListItem>
              <GSC.Icon>
                <IconTick size={16} fill="#A1A1A1" />
              </GSC.Icon>
              <GSC.IconListItemText>Order completed.</GSC.IconListItemText>
            </GSC.IconListItem>
          </GSC.IconList>

          <GSC.ButtonsContainer>
            <GSC.Button id="dismissModal" onClick={this.dismissThankYou}>
              Okay
            </GSC.Button>
          </GSC.ButtonsContainer>
        </Modal>
      </GSC.PageWrapper>
    );
  }
}
