import React from 'react';
import { Header, Footer, Grid, Row, Column, Heading } from '@lux/components';
import GSC from '../styles';

export default class ErrorPage extends React.Component {
  render() {
    let message = 'Oops, an error has occurred';
    const params = new URLSearchParams(this.props.location.search);
    if (params.get('code') === '401') {
      message = 'Oops, this link has expired';
    }

    return (
      <GSC.PageWrapper>
        <Header logo={{ href: '#', alt: 'Singtel.com' }} />

        <GSC.CenterArea>
          <Grid>
            <Row start="sm" center="md">
              <Column lg={4} md={6} sm={12}>
                <GSC.ContentWrapperCentered>
                  <Heading id="error-title" level={1}>
                    {message}
                  </Heading>
                </GSC.ContentWrapperCentered>
              </Column>
            </Row>
          </Grid>
        </GSC.CenterArea>

        <GSC.FooterContainer>
          <Footer
            copyrightText="© Singtel (CRN: 199201624D) All Rights Reserved."
            links={[
              { text: 'DATA PROTECTION', link: '#' },
              { text: 'CAREERS', link: '#' },
              { text: 'TERMS OF USE', link: '#' },
              { text: 'COPYRIGHT NOTICES', link: '#' },
              { text: 'CONTACT US', link: '#' },
              { text: 'SINGTEL GLOBAL OFFICES', link: '#' },
              { text: 'STORE LOCATOR', link: '#' }
            ]}
          />
        </GSC.FooterContainer>
      </GSC.PageWrapper>
    );
  }
}
