import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ChangePasswordPage from './components/ChangePasswordPage/ChangePasswordPage';
import ConfirmOrderPage from './components/ConfirmOrderPage/ConfirmOrderPage';
import VerifyIdentityPage from './components/VerifyIdentityPage/VerifyIdentityPage.js';
import ErrorPage from './components/ErrorPage/ErrorPage.js';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/change-password" exact component={ChangePasswordPage} />
          <Route path="/verify-identity" exact component={VerifyIdentityPage} />
          <Route path="/confirm-order" exact component={ConfirmOrderPage} />
          <Route path="/error" exact component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.querySelector('#root'));
