import React from 'react';
import {
  Header,
  Footer,
  Grid,
  Row,
  Column,
  Heading,
  Modal
} from '@lux/components';
import GSC from '../styles';
import axios from 'axios';

const regexLowerCase = /[a-z]/;
const regexUpperCase = /[A-Z]/;
const regexNumber = /[0-9]/;
const regexSpecial = /[!@#$%^&*(),.?":{}|<>]/;

export default class ChangePasswordPage extends React.Component {
  constructor(params) {
    super(params);

    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      newPasswordError: '',
      confirmNewPasswordError: '',
      isSuccessModalOpened: false,
      isValidatingToken: true,
      token: '',
      isSubmitDisabled: true
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (!params.has('token')) {
      this.props.history.push('/error');
    } else {
      // validate token
      axios
        .post(`${process.env.LUX_API_URL}/api/v1/password/validate`, {
          token: params.get('token')
        })
        .then(() => {
          this.setState({
            isValidatingToken: false,
            token: params.get('token')
          });
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.props.history.push('/error?code=401');
            return;
          }
          this.props.history.push('/error');
        });
    }
  }

  onChangeHandler = key => {
    return event => {
      this.setState({
        [key]: event.target.value
      });

      // To enable/disable submit button
      this.checkIsSubmitDisabled({ [key]: event.target.value });
    };
  };

  onBlur = () => {
    // If new password is filled, validate character types
    if (this.state.newPassword && !this.validate(this.state.newPassword)) {
      this.setState({
        newPasswordError:
          'New password must contain uppercase, lowercase, numbers and symbols'
      });
    } else {
      this.setState({
        newPasswordError: ''
      });
    }

    // Also, ensure both have the same values
    if (
      this.state.newPassword &&
      this.state.confirmNewPassword &&
      this.state.confirmNewPassword !== this.state.newPassword
    ) {
      this.setState({
        confirmNewPasswordError: 'Passwords do not match'
      });
    } else {
      this.setState({
        confirmNewPasswordError: ''
      });
    }
  };

  onSubmit = () => {
    // If form is not valid, dont do anything
    const isSubmitDisabled = this.checkIsSubmitDisabled();
    if (isSubmitDisabled) return;

    this.setState({
      isSettingPassword: true
    });
    setTimeout(() => this.checkIsSubmitDisabled(), 1);

    axios
      .post(`${process.env.LUX_API_URL}/api/v1/password/set`, {
        token: this.state.token,
        password: this.state.newPassword
      })
      .then(() => {
        this.setState({
          isSettingPassword: false,
          isSuccessModalOpened: true,
          newPassword: '',
          confirmNewPassword: ''
        });

        this.checkIsSubmitDisabled({ isSettingPassword: false });
      })
      .catch(() => {
        this.props.history.push('/error');
      });
  };

  checkIsSubmitDisabled = (overrides = {}) => {
    const inputs = {
      newPassword: this.state.newPassword,
      confirmNewPassword: this.state.confirmNewPassword,
      newPasswordError: this.state.newPasswordError,
      confirmNewPasswordError: this.state.confirmNewPasswordError,
      isSettingPassword: this.state.isSettingPassword,
      ...overrides
    };

    const isValid =
      inputs.newPassword &&
      inputs.confirmNewPassword &&
      this.validate(inputs.newPassword) &&
      this.validate(inputs.confirmNewPassword) &&
      inputs.newPassword === inputs.confirmNewPassword &&
      !inputs.newPasswordError &&
      !inputs.confirmNewPasswordError &&
      !inputs.isSettingPassword;

    this.setState({
      isSubmitDisabled: !isValid
    });
    return !isValid;
  };

  validate = value => {
    return Boolean(
      value.match(regexLowerCase) &&
        value.match(regexUpperCase) &&
        value.match(regexNumber) &&
        value.match(regexSpecial)
    );
  };

  closeSuccessModal = () => {
    this.setState({
      isSuccessModalOpened: false
    });
  };

  render() {
    const form = (
      <div>
        <GSC.TextFieldContainer>
          <GSC.Label>New password</GSC.Label>
          <GSC.TextField
            id="newPassword"
            type="password"
            placeholder="New password"
            value={this.state.newPassword}
            onChange={this.onChangeHandler('newPassword')}
            onBlur={this.onBlur}
            validation={this.state.newPasswordError ? 'danger' : null}
            hintMessage={this.state.newPasswordError}
          />
        </GSC.TextFieldContainer>

        <GSC.TextFieldContainer>
          <GSC.Label>Re-enter new password</GSC.Label>
          <GSC.TextField
            id="confirmNewPassword"
            value={this.state.confirmNewPassword}
            type="password"
            placeholder="Re-enter new password"
            onChange={this.onChangeHandler('confirmNewPassword')}
            onBlur={this.onBlur}
            validation={this.state.confirmNewPasswordError ? 'danger' : null}
            hintMessage={this.state.confirmNewPasswordError}
          />
        </GSC.TextFieldContainer>
      </div>
    );

    return (
      <GSC.PageWrapper>
        <Header logo={{ href: '#', alt: 'Singtel.com' }} />

        <GSC.CenterArea>
          <Grid>
            <Row start="sm" center="md">
              <Column lg={4} md={6} sm={12}>
                <GSC.ContentWrapperCentered>
                  <Heading level={1}>Confirm Your Password</Heading>

                  {form}
                </GSC.ContentWrapperCentered>
              </Column>
            </Row>

            <Row center="sm" end="md">
              <Column sm={12}>
                <GSC.ButtonsContainer>
                  <GSC.Button
                    id="submit"
                    disabled={this.state.isSubmitDisabled}
                    onClick={this.onSubmit}
                  >
                    Confirm
                  </GSC.Button>
                </GSC.ButtonsContainer>
              </Column>
            </Row>
          </Grid>
        </GSC.CenterArea>

        <GSC.FooterContainer>
          <Footer
            copyrightText="© Singtel (CRN: 199201624D) All Rights Reserved."
            links={[
              { text: 'DATA PROTECTION', link: '#' },
              { text: 'CAREERS', link: '#' },
              { text: 'TERMS OF USE', link: '#' },
              { text: 'COPYRIGHT NOTICES', link: '#' },
              { text: 'CONTACT US', link: '#' },
              { text: 'SINGTEL GLOBAL OFFICES', link: '#' },
              { text: 'STORE LOCATOR', link: '#' }
            ]}
          />
        </GSC.FooterContainer>

        <Modal open={this.state.isSuccessModalOpened}>
          <GSC.ModalHeader>Password Updated</GSC.ModalHeader>

          <GSC.ModalText>
            Your password has been updated successfully
          </GSC.ModalText>

          <GSC.ButtonsContainer>
            <GSC.Button onClick={this.closeSuccessModal}>Okay</GSC.Button>
          </GSC.ButtonsContainer>
        </Modal>
      </GSC.PageWrapper>
    );
  }
}
